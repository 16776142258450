<template>
  <div>
    <b-row>
      <!--Tabs-->
      <b-col
        cols="12"
        class="mb-3"
      >
        <b-tabs align="center">
          <b-tab
            v-for="(item, index) in status"
            :key="index"
            :title="item.label"
            @click="fetchOrders(item.value)"
          />
        </b-tabs>
      </b-col>
    </b-row>

    <MyTable
      :rows="rows"
      :columns="columns"
      :date-range-enabled="true"
      :date-range-field="'pickup_date'"
      :is-loading="isLoading"
      :has-export="true"
      :exporting="isExporting"
      @handleExport="handleReportExport"
    >
      <template v-slot="{ props }">
        <!-- ORDER ID -->
        <span v-if="props.column.field === 'id'">
          <b-button
            variant="primary"
            class="w-100"
            :to="`/chartered-truck/order-details/${props.row.id}`"
          >
            C{{ props.row.id }}
          </b-button>
        </span>

        <!--PICKUP -->
        <span v-else-if="props.column.field === 'pickup_date'">
          {{ props.row.pickup_date ? $helpers.formatDate(props.row.pickup_date) : '-' }}
        </span>

        <!-- RECIPIENT ADDRESS -->
        <span v-else-if="props.column.field === 'chartered_orders'">
          {{ props.row.chartered_orders[0] && props.row.chartered_orders[0].recipient && props.row.chartered_orders[0].recipient.complete ? props.row.chartered_orders[0].recipient.complete : "-" }}
        </span>

        <!-- STATUS -->
        <span v-else-if="props.column.field === 'status'">
          <span>
            <b-badge
              :variant="props.row.status.color_name"
              class="w-100 p-1 font-weight-bolder"
            >
              {{ getStatusText(props.row.status) }}
            </b-badge>
          </span>
        </span>

        <!-- QUOTATION -->
        <span v-else-if="props.column.field === 'quotation'">
          <span v-if="!props.row.quote"> N/A </span>
          <span v-else> RM {{ props.row.total_price }} </span>
        </span>

        <!-- DOCUMENTS -->
        <span v-else-if="props.column.field === 'documents'">
          <b-button
            v-if="props.row.quote"
            variant="secondary"
            class="rounded"
            @click="handleAction('quotation document', props.row)"
          >
            <feather-icon
              icon="InfoIcon"
              size="15"
              class="text-white align-middle mr-25"
            />
            View
          </b-button>
          <span v-else> - </span>
        </span>

        <!-- ACTION -->
        <span v-else-if="props.column.field === 'actions'">
          <div v-if="currentStatus === 'history' || currentStatus === 'cancelled'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item disabled> No actions available </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-else>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('update-status', props.row)">
                Change status
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <!--Back to Dashboard Button-->
    <b-row>
      <b-col cols="12 text-center">
        <b-button
          variant="danger"
          :to="{ name: 'dashboard' }"
          class="mr-1"
        >
          Back to Dashboard
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      ref="modal"
      :size="modalSize"
      :title="modalTitle"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        v-if="modalType === 'quotation document'"
        :order="selectedRow"
        @success="handleModalClosure"
      >
        <div>
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1">
            <span class="mx-2 font-weight-bold"> Quoted Documents </span>
            <hr class="flex-grow-1">
          </div>
          <div class="text-center">
            <div
              v-if="selectedRow.quote && selectedRow.quote.documents.length"
              class="d-flex justify-content-center flex-wrap"
            >
              <div
                v-for="(document, index) in selectedRow.quote.documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                  @click="openModal(document.path)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="25"
                  />
                  {{ document.name }}</b-button>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-center"
            >
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>

        <div class="my-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1">
            <span class="mx-2 font-weight-bold"> Customer Documents </span>
            <hr class="flex-grow-1">
          </div>
          <div class="text-center">
            <div
              v-if="selectedRow.customer_documents && selectedRow.customer_documents.length"
              class="d-flex justify-content-center flex-wrap"
            >
              <div
                v-for="(document, index) in selectedRow.customer_documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                  @click="openModal(document.document)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="25"
                  />
                  {{ document.document_name }}</b-button>
                <b-button
                  class="btn-sm"
                  variant="primary"
                  @click="deleteFile(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                    class="text-white align-middle"
                  />
                </b-button>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-center"
            >
              <div>
                <a> No Customer Document </a>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1">
            <span class="mx-2 font-weight-bold"> Receipts </span>
            <hr class="flex-grow-1">
          </div>
          <div
            v-if="selectedRow.payment_session_id"
            class="text-center"
          >
            <b-row>
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />

                  <a
                    href="#"
                    @click.prevent="handleDownload(selectedRow.payment_session_id, 'do')"
                  >
                    Delivery Order
                  </a>
                </div></b-col>
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />
                  <a
                    href="#"
                    @click.prevent="handleDownload(selectedRow.payment_session_id, 'inv')"
                  >
                    Invoice
                  </a>
                </div></b-col>
              <b-col cols="3">
                <div class="rounded border py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />
                  <a
                    href="#"
                    @click.prevent="handleDownload(selectedRow.payment_session_id, 'grn')"
                  >
                    Good Return Note
                  </a>
                </div></b-col>
              <b-col cols="3">
                <div class="rounded border px-2 py-1">
                  <feather-icon
                    style="color: red"
                    icon="DownloadIcon"
                    size="15"
                  />

                  <a
                    href="#"
                    @click.prevent="handleDownload(selectedRow.payment_session_id, 'cover')"
                  >
                    Covernote
                  </a>
                </div></b-col>
            </b-row>
          </div>
          <div
            v-else
            class="d-flex justify-content-center"
          >
            <div>
              <a> No Receipts </a>
            </div>
          </div>
        </div>
      </div>
      <UpdateStatus
        v-else-if="modalType === 'update-status'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
    </b-modal>
  </div>
</template>

<script>
import { STATUS_OPTIONS_FLEET } from '@/common/Constant'
import { MyTable } from '@/components'
import axios from '@axios'
import UpdateStatus from '@/components/Fleet/OrderTracker/UpdateStatus.vue'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'

export default {
  name: 'MyOrderTracker',
  components: {
    MyTable,
    UpdateStatus,
  },
  data() {
    const columns = [
      {
        label: 'Order ID',
        field: 'id',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'Lorry Type',
        field: 'lorry',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: true,
      },
      {
        label: 'Company Name',
        field: 'sender.name',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Pick Up Date',
        field: 'pickup_date',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Receiver Address',
        field: 'chartered_orders',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Quotation',
        field: 'quotation',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle text-center text-nowrap',
        sortable: false,
      },
      {
        label: 'Document',
        field: 'documents',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle text-center text-nowrap',
        sortable: false,
      },
      {
        label: 'Status',
        field: 'status',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle text-center text-nowrap',
        sortable: false,
      },
      {
        label: 'Actions',
        field: 'actions',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
    ]

    const status = [
      { label: 'All', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Scheduled', value: 'scheduled' },
      { label: 'Pending Payment', value: 'pending_payment' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'History', value: 'history' },
    ]

    return {
      columns,
      status,
      rows: [],
      modalType: null,
      currentStatus: '',
      selectedRow: [],
      isLoading: false,
      isEditable: true,
      refundType: null,
    }
  },
  computed: {
    modalTitle() {
      if (this.modalType === 'accept_declined') {
        return 'Payment Details'
      }

      return this.modalType ? this.modalType.replace('-', ' ').toUpperCase() : ''
    },
    modalSize() {
      const modals = ['update-status', 'edit-order', 'accept_declined']

      return modals.includes(this.modalType) ? 'md' : 'lg'
    },
    // eslint-disable-next-line vue/return-in-computed-property
    handleShowQuotation() {},
  },
  created() {
    this.fetchOrders('')
  },
  methods: {
    async fetchOrders(type) {
      this.resetRow()
      this.isLoading = true
      const query = `type=${type}`
      const response = await axios.get(`order-tracker-chartered?${query}`)
      this.rows = response.data
      this.currentStatus = type
      this.isLoading = false
    },

    async handleAction(type, row, status = null) {
      const refundStatus = ['in-process', 'paid', 'decline-request', 'approved']
      if (refundStatus.includes(type)) {
        await axios.patch(`/update-refund-status/${row.refund.id}?status=${status}`)
        this.$swal({
          title: 'Update success!',
          icon: 'success',
          text: `You have updated the refund status of ${row.reference_id}`,
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.fetchOrders(this.currentStatus)
        return
      }

      this.modalType = type
      this.$refs.modal.show()
      this.selectedRow = row
    },

    async handleModalClosure() {
      await this.fetchOrders(this.currentStatus)
      this.$refs.modal.hide()
    },
    async resetRow() {
      this.rows = []
    },
    getStatusText(status) {
      const currentStatus = STATUS_OPTIONS_FLEET.find(item => item.id === status.value)
      return currentStatus ? currentStatus.text : status.text
    },
    handleReportExport(type) {
      const other = this.rows.map(item => ({
        // ...item,
        id: item.id,
        quantity: item.quantity,
        order_date: item.date_created,
        pickup_date: item.pickup_date,
        status: item.status.text,
        sender_name: item.sender.name,
        sender_contact: item.sender.phone,
        sender_address: item.sender.complete,
        lorry: item.lorry,
        recipient_contact: item.chartered_orders[0].recipient.phone,
        recipient_address: item.chartered_orders[0].recipient.complete,
      }))
      if (type == 'xlsx') {
        const ws = XLSX.utils.json_to_sheet(other)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'sheet')
        XLSX.writeFile(wb, `chartered_export${Date.now()}.xlsx`)
      } else if (type == 'csv') {
        const blob = new Blob([Papa.unparse(other)], { type: 'application/vnd.ms-excel;charset=utf-8;' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `chartered_export${Date.now()}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async handleDownload(id, type) {
      let link = ''
      let fileName = ''
      if (type === 'inv') {
        link = `/download-invoice/${
          this.selectedRow.payment.id ? this.selectedRow.payment.id : this.selectedRow.logpoint_id
        }/${this.selectedRow.payment_method}/chartered`
        fileName = `invoice-${this.selectedRow.id}`
      } else {
        link = `chartered-delivery-confirmed/${
          this.selectedRow.payment_session_id
            ? this.selectedRow.payment_session_id
            : this.selectedRow.logpoint_id
        }/${this.selectedRow.payment_method}/print/${type}`
        fileName = `${type}-${this.selectedRow.id}`
      }
      const response = await this.$http.get(link, {
        responseType: 'blob',
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${fileName}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}
</script>
